{
  "name": "smartflow-client",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0 --disable-host-check",
    "start:de": "ng serve --host 0.0.0.0 --disable-host-check --configuration=de",
    "extract:i18n": "ng extract-i18n",
    "build": "ng build && npm run sentry:sourcemaps",
    "build:stage": "ng build --configuration=staging",
    "watch": "ng build --watch --configuration=development",
    "test": "jest",
    "lint": "ng lint",
    "prettier": "prettier . --write",
    "cypress": "cypress open",
    "cypress:component": "cypress run --component",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org group24-ag-of --project smartflow-client ./dist && sentry-cli sourcemaps upload --org group24-ag-of --project smartflow-client ./dist",
    "release": "release-it",
    "release:ci": "release-it --ci"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/localize": "^17.3.0",
    "@angular/material": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-free": "^6.4.0",
    "@fortawesome/fontawesome-svg-core": "^6.4.2",
    "@fortawesome/free-regular-svg-icons": "^6.5.2",
    "@fortawesome/free-solid-svg-icons": "^6.4.2",
    "@ngrx/component-store": "^17.1.0",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngrx/store-devtools": "^17.1.0",
    "@sentry/angular-ivy": "^7.110.1",
    "@sentry/cli": "^2.31.0",
    "@tailwindcss/forms": "^0.5.3",
    "@types/jest": "^29.5.13",
    "chart.js": "^4.4.2",
    "cypress": "^13.14.2",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.2.4",
    "ng2-charts": "^6.0.0",
    "ngx-mat-select-search": "^7.0.5",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.0",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "~17.3.0",
    "@angular/compiler-cli": "^17.3.0",
    "@ngrx/eslint-plugin": "^17.0.1",
    "@release-it/conventional-changelog": "^9.0.2",
    "@types/node": "^22.9.0",
    "@typescript-eslint/eslint-plugin": "6.10.0",
    "@typescript-eslint/parser": "6.10.0",
    "autoprefixer": "^10.4.14",
    "eslint": "^8.53.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "ng-extract-i18n-merge": "^2.11.2",
    "postcss": "^8.4.24",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.5.4",
    "release-it": "^17.10.0",
    "tailwindcss": "^3.4.3",
    "ts-node": "^10.9.2",
    "typescript": "~5.2.2"
  }
}
